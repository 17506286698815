import { onTTFB, onFCP, onLCP, onCLS, onINP, Metric } from 'web-vitals';
import { useEffect, useState } from 'react';
import { isServer } from '@/shared/app/isServer';
import { useDeviceDetector } from '@/shared/utils/userAgentContext';
import { IBrowser } from 'ua-parser-js';
import { logger } from '@/shared/utils/logger';

export const useWebVitalsFx = (AITracks: any, AIReadyAsDep: boolean) => {
	const { webVitalsAllMetrics, webVitalsSingleMetric } = AITracks;
	const [LCP, setLCP] = useState<Metric | null>(null);
	const [FCP, setFCP] = useState<Metric | null>(null);
	const [TTFB, setTTFB] = useState<Metric | null>(null);
	const [CLS, setCLS] = useState<Metric | null>(null);
	const [INP, setINP] = useState<Metric | null>(null);
	const { browser, isIOS } = useDeviceDetector();
	const isIOSDetected = isIOS();
	const browserName: IBrowser = browser?.name as unknown as IBrowser;
	const isNotChromium: boolean =
		!(browserName as unknown as string)?.match?.(/chrome/gi) &&
		!(browserName as unknown as string)?.match?.(/chromium/gi);
	const isNotChromiumOrFF: boolean = isNotChromium && !(browserName as unknown as string)?.match?.(/firefox/gi);
	const isNotChromiumOrFFOrSafari: boolean =
		isNotChromiumOrFF && !(browserName as unknown as string)?.match?.(/safari/gi);
	const logMetric = (metric: Metric) => metric && logger.log(`WebVitals - ${metric?.name}:`, metric);
	const isInteractive = !isServer && typeof document !== 'undefined';
	const metricSeparateOptions = { reportAllChanges: true };
	const metricAllHandle = () => {
		logger.debug(
			'WebVitals - All metrics:',
			{ TTFB, FCP, LCP, CLS },
			`in "${browserName}" browser`,
			`please find vitals' browser support table here: "https://github.com/GoogleChrome/web-vitals?tab=readme-ov-file#browser-support"`,
		);
		// https://github.com/GoogleChrome/web-vitals?tab=readme-ov-file#browser-support
		// EVO-1041 - IOS Chrome support web-vitals bug report: https://github.com/GoogleChrome/web-vitals/issues/420
		AIReadyAsDep &&
			(CLS || isNotChromium || (!isNotChromium && isIOSDetected)) && // onCLS(): Chromium - but not IOS Chrome
			(LCP || isNotChromiumOrFF || (!isNotChromium && isIOSDetected)) && // onLCP(): Chromium, Firefox - but not IOS Chrome
			(FCP || isNotChromiumOrFFOrSafari) && // onFCP(): Chromium, Firefox, Safari - Only this one is supported on iOS Chrome robustly
			(TTFB || isNotChromiumOrFFOrSafari || (!isNotChromium && isIOSDetected)) && // onTTFB(): Chromium, Firefox, Safari - but not IOS Chrome
			webVitalsAllMetrics({
				TTFB: TTFB ?? (undefined as unknown as Metric),
				FCP: FCP ?? (undefined as unknown as Metric),
				LCP: LCP ?? (undefined as unknown as Metric),
				CLS: CLS ?? (undefined as unknown as Metric),
			});
	};

	useEffect(() => {
		if (isInteractive) {
			// ALL METRICS COLLECTION
			onTTFB((metric) => metric && metric.value !== TTFB?.value && setTTFB(metric));
			onFCP((metric) => metric && metric.value !== FCP?.value && setFCP(metric));
			onLCP((metric) => metric && metric.value !== LCP?.value && setLCP(metric));
			// SINGLE METRICS TRACKED
			onINP((metric) => metric && metric.value !== INP?.value && setINP(metric), metricSeparateOptions);
		}
		onCLS((metric) => metric && metric.value !== CLS?.value && setCLS(metric), metricSeparateOptions);
		document?.documentElement?.click();
	}, [isInteractive]);

	// SINGLE METRICS TRACK
	useEffect(() => {
		logMetric(TTFB as Metric);
		CLS && AIReadyAsDep && webVitalsSingleMetric('CLS', CLS as Metric);
	}, [isInteractive, AIReadyAsDep, CLS]);
	useEffect(() => {
		logMetric(INP as Metric);
		INP && AIReadyAsDep && webVitalsSingleMetric('INP', INP as Metric);
	}, [isInteractive, AIReadyAsDep, INP]);

	// ALL METRICS TRACK
	useEffect(() => {
		metricAllHandle();
	}, [isInteractive, AIReadyAsDep, LCP, FCP, TTFB, CLS]);
};
