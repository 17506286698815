'use client';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import CloseIcon from './CloseIcon';
import { LocalizedLink } from '../Link';
import { useUnit } from 'effector-react';
import { $arenaDataStore } from '@/app.model';
import styles from './styles.module.css';
import { $gameStateStore } from '@/features/games/model';
import classNames from 'classnames';
import { INGAME_GAME_STATES } from '@/shared/utils/constants';
import { TNotificationBar } from '@/shared/api/arena-data';
import { getBrowserCookie } from '@/shared/utils/utils';
import { Button } from '../Button';
import { useScreenSize } from '@/shared/utils/useScreensize';
import { usePathname, useRouter } from 'next/navigation';
import { getLocaleFromPathname } from '@/shared/utils/url';
import { AnalyticsContext } from '../Layout';

const MINIMUM_NOTIFICATION_BAR_LIFESPAN = 10000;
const COOLDOWN_TIME_UNTIL_NEXT_NOTIFICATION = 5000;
const COOKIE_DISMISSED_NOTIFICATION_KEY = 'evo_notifs';

export const NotificationBar: FC = () => {
	const router = useRouter();
	const pathname = usePathname();
	const notificationBarRef = useRef<HTMLDivElement>(null);
	const [hideNotification, setHideNotification] = useState<boolean>(false);
	const [autodismissData, setAutodismissData] = useState<any>({ startTime: 0, remainingTime: 0, timeoutRef: null });
	const [notifications, setNotifications] = useState<TNotificationBar[]>();
	const [currentNotificationIndex, setCurrentNotificationIndex] = useState<number>(-1);
	const arenaData = useUnit($arenaDataStore);
	const gameState = useUnit($gameStateStore);
	const { AITracks } = useContext(AnalyticsContext);
	const screenSize: any = useScreenSize();

	const {
		icon,
		title,
		text,
		link,
		cta,
		analyticId,
		dismissButton,
		closeIcon,
		autoDismiss,
		autoDismissTime,
		bgColor,
		closeColor,
		textColor,
		ctaStyling,
	}: TNotificationBar | Record<string, any> =
		notifications?.length && currentNotificationIndex >= 0 ? notifications[currentNotificationIndex] : {};
	const analyticsNotifType = autoDismiss ? 'expirable' : 'nonexpirable';
	const defaultAnalyticsDimensions = { notifId: analyticId, notifType: analyticsNotifType };

	const sendLinkAnalytics = (e: any, link: any, customDimension: any) => {
		const locale = getLocaleFromPathname(pathname);
		const route = `${locale}/${link.href}`;
		if (link.target === '_self') {
			e.preventDefault();
			AITracks.notificationAction(customDimension)
				.then(() => {
					router.push(route);
				})
				.catch(() => {
					router.push(route);
				});
			return;
		}
		AITracks.notificationAction(customDimension);
	};

	const dismissNotification = () => {
		const dismissedNotificationIds = getBrowserCookie(COOKIE_DISMISSED_NOTIFICATION_KEY)?.split('|') ?? [];
		dismissedNotificationIds.push(notifications![currentNotificationIndex]?.id.toString());
		const expiryDate = new Date(),
			month = (expiryDate.getMonth() + 1) % 12;
		expiryDate.setMonth(month);
		document.cookie = `${COOKIE_DISMISSED_NOTIFICATION_KEY}=${dismissedNotificationIds.join('|')}; expires=${expiryDate.toUTCString()}; Secure`;

		setHideNotification(true);
		setTimeout(() => {
			setHideNotification(false);
			const nextNotificationIndex =
				currentNotificationIndex + 1 >= notifications!.length ? -1 : currentNotificationIndex + 1;
			nextNotificationIndex !== -1 && AITracks.notificationImpression(defaultAnalyticsDimensions);
			setCurrentNotificationIndex(nextNotificationIndex);
		}, COOLDOWN_TIME_UNTIL_NEXT_NOTIFICATION);
	};

	const startAutodismissTimeout = (lifespan: number) => {
		const timeout = setTimeout(() => {
			dismissNotification();
			AITracks.notificationImpression({ ...defaultAnalyticsDimensions, notifAction: 'Expire' });
			setAutodismissData({ startTime: 0, remainingTime: 0, timeoutRef: null });
		}, lifespan);
		setAutodismissData({ startTime: Date.now(), remainingTime: lifespan, timeoutRef: timeout });
	};

	const calculateAutoDismissLifespan = () => {
		if (autoDismissTime) {
			const seconds = Number(autoDismissTime.split('-')?.at(-1));
			return seconds * 1000;
		}

		let lifespan = MINIMUM_NOTIFICATION_BAR_LIFESPAN;
		const totalWordCount = [title, text, link?.label]
			.map((i) => i?.split(' ')?.length ?? 0)
			.reduce((prev, curr) => prev + curr);
		if (totalWordCount > 15) {
			lifespan += 10000;
		} else if (totalWordCount > 10) {
			lifespan += 5000;
		}

		return lifespan;
	};

	// update top padding for page
	useEffect(() => {
		if (!notificationBarRef.current) return;
		document.documentElement.style.setProperty('--top-padding', `${notificationBarRef.current.clientHeight}px`);
	}, [screenSize, currentNotificationIndex, hideNotification]);

	// starting autodismiss timer, can't be autodismiss with a dismiss button
	useEffect(() => {
		if (!autoDismiss || dismissButton) return;
		const lifespan = calculateAutoDismissLifespan();
		startAutodismissTimeout(lifespan);
	}, [autoDismiss, dismissButton]);

	// pause/resume autodismiss timeout for tablet/mobile when gamestate changes
	useEffect(() => {
		if (!['xs', 'sm'].includes(screenSize) || !notifications?.length || !autoDismiss || dismissButton) {
			autodismissData.timeoutRef && startAutodismissTimeout(autodismissData.remainingTime);
			return;
		}

		if (INGAME_GAME_STATES.includes(gameState) && autodismissData.timeoutRef) {
			clearTimeout(autodismissData.timeoutRef);
			setAutodismissData((data: any) => ({
				...data,
				timeoutRef: null,
				remainingTime: data.remainingTime - (Date.now() - data.startTime),
			}));
		}
		if (
			!INGAME_GAME_STATES.includes(gameState) &&
			!autodismissData.timeoutRef &&
			autodismissData.remainingTime > 0
		) {
			startAutodismissTimeout(autodismissData.remainingTime);
		}
	}, [screenSize, notifications, autoDismiss, dismissButton, gameState]);

	useEffect(() => {
		const { notification_bars } = arenaData;
		if (!notification_bars?.length) {
			return;
		}

		const dismissedNotificationIds = getBrowserCookie(COOKIE_DISMISSED_NOTIFICATION_KEY)?.split('|');
		if (!dismissedNotificationIds?.length) {
			setNotifications(notification_bars);
			setCurrentNotificationIndex(0);
			AITracks.notificationImpression(defaultAnalyticsDimensions);
			return;
		}

		const activeNotifications = notification_bars.filter(
			(n) => (!n.autoDismiss && !n.dismissButton) || !dismissedNotificationIds?.includes(n.id.toString()),
		);
		if (!activeNotifications?.length) {
			setCurrentNotificationIndex(-1);
			return;
		}
		setNotifications(activeNotifications);
		setCurrentNotificationIndex(0);
		AITracks.notificationImpression(defaultAnalyticsDimensions);
	}, [arenaData]);

	return (
		<div
			ref={notificationBarRef}
			className={classNames(styles.notificationBar, {
				[styles.hidden]:
					hideNotification ||
					currentNotificationIndex === -1 ||
					(INGAME_GAME_STATES.includes(gameState) && screenSize && ['xs', 'sm'].includes(screenSize)),
			})}
			style={
				{
					'--background-color': bgColor,
					'--text-color': textColor,
					'--close-color': closeColor,
				} as React.CSSProperties
			}
		>
			<div className={styles.content}>
				{icon && <img width='32' height='32' className={styles.icon} src={icon.url} aria-hidden />}
				<p className={styles.text}>
					{title ?? ''}
					{text && (
						<>
							<br />
							{text}
						</>
					)}
					{link && (
						<>
							&nbsp;
							<LocalizedLink
								className={styles.link}
								href={link.href}
								target={link.target}
								onClick={(e) => {
									sendLinkAnalytics(e, link, {
										...defaultAnalyticsDimensions,
										notifAction: 'Text Link',
									});
								}}
							>
								{link.label}
							</LocalizedLink>
						</>
					)}
				</p>
				{cta && (
					<LocalizedLink
						className={styles.cta}
						href={cta.href}
						target={cta.target}
						onClick={(e) => {
							sendLinkAnalytics(e, cta, { ...defaultAnalyticsDimensions, notifAction: 'CTA' });
						}}
					>
						<Button className={styles.ctaButton} variation='outline' overrides={ctaStyling}>
							<div className={styles.ctaButtonContent}>
								{cta.icon && (
									<img width='24' height='24' src={cta.icon.url} alt='Button Icon' aria-hidden />
								)}
								{cta.label}
							</div>
						</Button>
					</LocalizedLink>
				)}
			</div>
			{dismissButton && (
				<button
					className={styles.closeButton}
					type='button'
					onClick={() => {
						AITracks.notificationAction({ ...defaultAnalyticsDimensions, notifAction: 'Close' });
						dismissNotification();
					}}
					aria-label='Close notification'
				>
					{closeIcon ? (
						<img width='32' height='32' src={closeIcon.url} aria-label='Close notification' />
					) : (
						<CloseIcon />
					)}
				</button>
			)}
		</div>
	);
};
