import React from 'react';
import styles from './styles.module.css';

const CloseIcon: React.FC = () => {
	return (
		<svg
			className={styles.closeIcon}
			width='32'
			height='32'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M24 8L8 24M8 8L24 24'
				stroke='currentColor'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default CloseIcon;
