import React from 'react';
import styles from './styles.module.css';

interface SearchIconProps {
	className?: string;
}

const SearchIcon: React.FC<SearchIconProps> = ({ className }) => {
	return (
		<svg
			className={`${styles.searchIconSvg} ${className || ''}`}
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M21 21L15.0001 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z'
				stroke='currentColor'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default SearchIcon;
