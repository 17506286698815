import { LocalizedLink } from '@/components/Link';
import classNames from 'classnames';
import styles from './styles.module.css';
import { ButtonOverride } from '@/shared/api/arena-data';
import { buildButtonCSSOverrides } from '@/shared/utils/utils';
import { useDeviceDetector } from '@/shared/utils/userAgentContext';

export const Button = (props: {
	variation?: 'fill' | 'outline';
	className?: string;
	href?: string;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
	overrides?: ButtonOverride[] | [];
	children?: React.ReactNode;
	focusable?: boolean;
	staticStyling?: boolean;
}) => {
	const { isDesktop } = useDeviceDetector();
	const isDesktopDetected = isDesktop();
	const classes = classNames(
			styles.button,
			props?.className,
			styles[props?.variation ?? 'fill'],
			props?.staticStyling ? styles.static : undefined,
		),
		overrideStyles = buildButtonCSSOverrides(isDesktopDetected, props?.overrides ?? []),
		attributes = {
			className: classes,
			style: overrideStyles,
			onClick: props.onClick,
			tabIndex: props?.focusable === false ? -1 : 0,
		};
	const StyleGradientSupported = () =>
		!overrideStyles?.['--normal-background'] ? null : (
			<style
				dangerouslySetInnerHTML={{
					__html: `
            .${styles.button} {
                --normal-background: ${overrideStyles?.['--normal-background']};
        `,
				}}
			/>
		);
	if (props?.href) {
		return (
			<LocalizedLink href={props?.href} target='_self' {...(attributes as any)}>
				<StyleGradientSupported />
				{props?.children}
			</LocalizedLink>
		);
	}
	return (
		<button type={'button'} {...(attributes as any)}>
			<StyleGradientSupported />
			{props?.children}
		</button>
	);
};
