'use client';
import { useState, useEffect, useCallback, useContext } from 'react';
import { useDebounce } from '@uidotdev/usehooks';
import { AnalyticsContextView } from '@/features/arena-data/view';
import { sanitizeSearch } from '@/features/sanitize/sanitizeSearch';
import type Fuse from 'fuse.js';
import type { IFuseOptions } from 'fuse.js';

export const useSearch = (
	data: any[],
	options?: IFuseOptions<any>,
	rerankResults?: (results: any[]) => any[],
	debounceDelay: number = 200,
	minQueryLength: number = 2,
) => {
	const [fuse, setFuse] = useState<Fuse<any> | null>(null);
	const [query, setQuery] = useState('');
	const [results, setResults] = useState<any[] | null>(null);
	const [malicious, setMalicious] = useState<boolean>(false);
	const [isSearching, setIsSearching] = useState(false);
	const debouncedQuery = useDebounce(query, debounceDelay);
	const { AITracks } = useContext(AnalyticsContextView);

	// Memoize the search function
	const search = useCallback(
		async (searchQuery: string) => {
			let searchResults: any[] = [];
			// Client-side sanitization of search query
			const { isMalicious, sanitizedQuery } = sanitizeSearch(searchQuery);
			setMalicious(isMalicious); // if query changed from malicious to non-malicious or vice versa - update state
			if (isMalicious) return; // if is malicious query - do not search, the state of malicious will be handled with notification div

			// lazy load fuse.js
			let fuseInstance: Fuse<any> | null = fuse ?? null;
			if (!fuseInstance) {
				const importedModule = await import('fuse.js');
				fuseInstance = new importedModule.default(data, options);
				setFuse(fuseInstance);
			}

			// Executing search for results
			if (sanitizedQuery !== '') {
				searchResults = fuseInstance.search(sanitizedQuery);
			}
			// Apply custom reranking logic if provided
			if (rerankResults) {
				searchResults = rerankResults(searchResults);
			}
			setResults(searchResults);
		},
		[fuse, rerankResults],
	);

	useEffect(() => {
		if (!data || !options) {
			return;
		}
		setIsSearching(true);
		if (debouncedQuery.length > minQueryLength) {
			AITracks.searchBoxQuery(debouncedQuery);
			search(debouncedQuery);
			setIsSearching(false);
		} else {
			setResults(null);
		}
	}, [debouncedQuery, search, data, options]);

	return { query, setQuery, results, isSearching, isMalicious: malicious };
};
