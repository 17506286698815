/* eslint-disable react/no-is-mounted */
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { detectIncognito } from 'detectincognitojs';
import { LocalStorageService } from '../../../shared/utils/local-storage';
import { initFPStore } from './model';
import { logger } from '@/shared/utils/logger';

class FingerprintServiceBase {
	lsPropName = 'FP_VISITOR';

	async init() {
		const result = JSON.stringify(await this.detect());
		LocalStorageService.setItem(this.lsPropName, result);
		return result;
	}

	async detect() {
		const fingerprintId = await this.getFingerprint();
		const fpVisitorId = this?.detected?.fpVisitorId ?? fingerprintId;
		const fpIncognito = await this.isIncognito();

		const result = { fpVisitorId, fpIncognito };
		logger.info('FingerprintService', 'detect!', result);
		return result;
	}

	get detected() {
		const detectedJSON = LocalStorageService.getItem(this.lsPropName);
		let detected = initFPStore;
		try {
			detected = detectedJSON ? JSON.parse(detectedJSON) : null;
		} catch (e) {
			logger.error(e);
		}
		logger.info('FingerprintService', 'get detected', detected);
		return detected;
	}

	async getFingerprint() {
		const FP = await FingerprintJS.load();
		const result = await FP.get();
		return result?.visitorId;
	}

	async isIncognito() {
		const result = await detectIncognito();
		return result?.isPrivate;
	}

	remove() {
		LocalStorageService.removeItem(this.lsPropName);
	}
}

export const FingerprintService = new FingerprintServiceBase();
