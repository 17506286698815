import { TExternalScript } from '@/shared/api/arena-data';
import React from 'react';
import { init } from '@/shared/internal-scripts-test/mockAppInsight';

const getId = (prefix: string, name: string) => `${prefix}-${name}`;

export const buildInternalScript = (script: TExternalScript, onLoad: (name: string) => void) => {
	//TODO: manage real packages import based on internal mainScript value
	const MainScript = () => {
		if (script.mainScript[0] === 'AppInsight') {
			try {
				init(); //TODO: switch to actual packages
				onLoad(script.name);
			} catch (error) {
				console.error('Error importing the mock package:', error);
			}
		}
		return null;
	};
	return (
		<React.Fragment key={getId('before-internal-main-script', script.name)}>
			<MainScript />
		</React.Fragment>
	);
};
