export const MAX_QUERY_CHARS = 35; // enough for game name / slug / partial description
const INVALID_QUERY_CHARS = /[^a-z0-9 _-]/gi; // only allow alphanumeric, space and dashes

export type TSanitizedSearchResult = {
	isMalicious: boolean;
	sanitizedQuery: string;
};

export const sanitizeSearch = (query: string): TSanitizedSearchResult => {
	if (!query || typeof query !== 'string') {
		return {
			isMalicious: false,
			sanitizedQuery: '',
		};
	}
	return {
		isMalicious: Boolean(query.length > MAX_QUERY_CHARS || query.match(INVALID_QUERY_CHARS)),
		sanitizedQuery: query.replaceAll(INVALID_QUERY_CHARS, '').trim().slice(0, MAX_QUERY_CHARS).trim(),
	};
};
