import { hexToRgb } from '@/uikit/helper_funcs/hexToRgb';

export const buttonBackgroundCalculation = ({
	override_button_normal_background,
	override_button_normal_background_end,
	override_button_hover_background,
	override_button_hover_background_end,
	override_button_active_background,
	override_button_active_background_end,
	override_button_focus_background,
	override_button_focus_background_end,
	isHoverBlock = false,
}: {
	override_button_normal_background?: null | string;
	override_button_normal_background_end?: null | string;
	override_button_hover_background?: null | string;
	override_button_hover_background_end?: null | string;
	override_button_active_background?: null | string;
	override_button_active_background_end?: null | string;
	override_button_focus_background?: null | string;
	override_button_focus_background_end?: null | string;
	isHoverBlock?: boolean;
}) => {
	const isGradientButton = (cssBcgValue: string) => cssBcgValue.match(/linear-gradient/gi);
	const getFinal = (startColor: string, endColor: string) =>
		startColor && endColor && startColor !== endColor
			? `linear-gradient(90deg, ${startColor} 0%, ${endColor} 100%)`
			: startColor;
	const bcgNormal = getFinal(override_button_normal_background || '', override_button_normal_background_end || '');
	const bcgHover = getFinal(override_button_hover_background || '', override_button_hover_background_end || '');
	const activePretest = getFinal(
		override_button_active_background || '',
		override_button_active_background_end || '',
	);
	const activeStart =
		isHoverBlock && override_button_active_background && isGradientButton(activePretest)
			? hexToRgb(override_button_active_background) + ', 0.7)'
			: override_button_active_background;
	const activeEnd =
		isHoverBlock && override_button_active_background_end && isGradientButton(activePretest)
			? hexToRgb(override_button_active_background_end) + ', 0.7)'
			: override_button_active_background_end;
	const bcgActive = getFinal(activeStart as string, activeEnd as string);
	const bcgFocus = getFinal(override_button_focus_background || '', override_button_focus_background_end || '');
	return { bcgNormal, bcgHover, bcgActive, bcgFocus };
};
