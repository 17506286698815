import { AppInsightService } from '@arkadium/modules/dist/lib/_shared/services/AppInsightService';
import { trackEvent } from '@arkadium/modules/dist/lib/Analytics/utils';
import { TAnalyticsBaseParams } from '@arkadium/modules/dist/lib/Analytics/models/AnalyticsEventBuilder/TEventBaseParams';
import { AnalyticsEventAction } from '@arkadium/modules/dist/lib/Analytics/constants/AnalyticsContants';
import { useEffect, useRef, useState } from 'react';
import { useUnit } from 'effector-react';
import { $FingerprintStore } from '@/features/analytics/Fingerprint/model';
import { DeviceType, useDeviceDetector } from '@/shared/utils/userAgentContext';
import { $arenaDataStore, $homePageStore, AnalyticsPageTypes } from '@/app.model';
import { getSearchParams } from '@/shared/utils/url';
import { isServer } from '@/shared/app/isServer';
import { AiData, baseParams } from '@/features/analytics/AI/index';
import { $gameData } from '@/root/app/[locale]/games/[slug]/model';
import { trackGameEvent } from '@arkadium/modules/dist/lib/Analytics/AnalyticsEventBuilder';
import { TLink } from '@/components/Footer';
import { IModularPage } from '@/shared/api/pages';
import { $gdprConsentStore, $isGDPRApplies, $targetingConsentStore } from '@/features/analytics/model';
import { Metric } from 'web-vitals';
import type { IArkcomGame } from '@arkadium/modules';
import type { GeneralAnalytics as ModulesGeneralAnalytics } from '@arkadium/modules/dist/lib/Analytics/GeneralAnalytics';
import type { GamesAnalytics as ModulesGameAnalytics } from '@arkadium/modules/dist/lib/Analytics/GamesAnalytics';
import { $AILoadErrors, $AIStore } from '@/features/analytics/AI/model';
import { IBrowser } from 'ua-parser-js';
import { logger } from '@/shared/utils/logger';

class Analytics<T> {
	private callback: () => Promise<unknown>;
	private instance: T | undefined;
	private promise: Promise<any> | undefined;
	private key: string;

	constructor(callback: () => Promise<unknown>, key: string) {
		this.callback = callback;
		this.key = key;
	}

	private async importModule() {
		if (!this.promise) this.promise = this.callback();
		return this.promise;
	}
	public async getInstance() {
		if (this.instance) return this.instance as T;
		try {
			const importedModule = await this.importModule();
			this.instance = new importedModule[this.key]();
		} catch (e) {
			// it is still in suspense mode, will be updated next try
			logger.error('AI - Analytics instance not ready, ', e);
		}
		return this.instance as T;
	}
}
const GamesAnalytics = new Analytics<ModulesGameAnalytics>(
	() => import('@arkadium/modules/dist/lib/Analytics/GamesAnalytics'),
	'GamesAnalytics',
);
const GeneralAnalytics = new Analytics<ModulesGeneralAnalytics>(
	() => import('@arkadium/modules/dist/lib/Analytics/GeneralAnalytics'),
	'GeneralAnalytics',
);

const GENERIC_PAGES_ARR: AnalyticsPageTypes[] = [
	AnalyticsPageTypes.GENERIC,
	AnalyticsPageTypes.GENERIC_HOME,
	AnalyticsPageTypes.CATEGORY,
];

export type TPlayAgainStart = 'Yes' | 'No';
export type TPlayAgainStartParam = {
	playAgainStart?: TPlayAgainStart;
};
export type TRecommendedGameStart = {
	recommendedGameStart?: 'Yes' | 'No';
};

export const useAnalytics = (genericPageData?: IModularPage, pageTypePassed?: AnalyticsPageTypes) => {
	logger.debug('AI - passed data: ', genericPageData, pageTypePassed);
	const aiDataRef = useRef<AiData>();
	const aiData = aiDataRef.current as AiData;
	const [AIReady, setAIReady] = useState(false);
	// prepared data
	const fingerprintData = useUnit($FingerprintStore);
	const arenaData = useUnit($arenaDataStore);
	const AILoadReady: boolean = useUnit($AIStore);
	const AILoadErrors: any = useUnit($AILoadErrors);
	const gdprConsent = useUnit($gdprConsentStore);
	const targetingConsent = useUnit($targetingConsentStore);
	const isGDPRApplies = useUnit($isGDPRApplies);
	const homePage = useUnit($homePageStore);
	const game: IArkcomGame | null = useUnit($gameData) as unknown as IArkcomGame;
	const gameSlug = (game as any)?.slug ?? '';
	const { browser, detectDevice } = useDeviceDetector();
	const browserName: IBrowser = browser?.name as unknown as IBrowser;
	const deviceType: DeviceType = detectDevice();
	// used data
	const { fpVisitorId, fpIncognito, fpCheckDone } = fingerprintData;
	// @ts-ignore
	const buildVersion = process.env.buildid ?? 'dev';
	const arenaVersion = 'arena-evolution';
	const loggedIn = false; /// TODO: add when have this logic
	const modularPageIDSaved = !isServer ? (window as any)?.modularPageID : null;
	const [modularPageID, setModularPageID] = useState<any>(genericPageData?.analyticId ?? modularPageIDSaved ?? null);
	const arenaLayoutID = arenaData?.layout?.analyticId ?? null;
	const [isGenericPage, setIsGenericPage] = useState(false);
	const [pageType, setPageType] = useState<AnalyticsPageTypes>(AnalyticsPageTypes.GENERIC_HOME);
	const arenaID = arenaData?.analyticId ?? null;
	const slugCategories = arenaData?.categoriesSlug ?? null;
	// errors information
	AILoadReady && logger.info('AI - Load finished!');
	AILoadErrors && logger.info('AI - Loading error: ', AILoadErrors);
	// page type
	const pathName = !isServer ? window.location.pathname : '';
	const checkPathType = (pathCheckName: string) =>
		pathName.includes(pathCheckName) &&
		(pathName.match(new RegExp(`\\/${pathCheckName}\\/`, 'gi')) ||
			pathName.match(new RegExp(`\\/${pathCheckName}\\/?$`, 'gi')));
	const getPageType = (): AnalyticsPageTypes =>
		pageTypePassed ??
		// this logic is just-for-case fallback: WE PASS pageTypePassed on layout level context by every page
		(gameSlug && checkPathType('games')
			? AnalyticsPageTypes.GAMEPAGE
			: checkPathType('search')
				? AnalyticsPageTypes.SEARCH
				: checkPathType('tags')
					? AnalyticsPageTypes.TAGS
					: slugCategories &&
						  (checkPathType(slugCategories) || checkPathType('category') || checkPathType('categories'))
						? AnalyticsPageTypes.CATEGORY
						: homePage?.slug === genericPageData?.slug
							? AnalyticsPageTypes.GENERIC_HOME
							: genericPageData?.slug
								? AnalyticsPageTypes.GENERIC
								: AnalyticsPageTypes.UNKNOWN);
	// handle change of page type in case of not full reload
	useEffect(() => {
		setPageType(getPageType());
	}, [pageType]);
	// handle modularPageID for root layout components' tracks
	useEffect(() => {
		if (genericPageData && modularPageID) {
			// provide modularPageID from layout to call in root layout on higher level
			(window as any).modularPageID = modularPageID; // child-layout stores modularPageID in window
		}
		if (!genericPageData && !modularPageID && modularPageIDSaved) {
			// get modularPageID into root layout
			modularPageIDSaved && setModularPageID((window as any).modularPageID); // root layout gets modularPageID from window after mounting inner layout as child
			(window as any).modularPageID = undefined; // cleanup
		}
	}, [genericPageData, modularPageID, modularPageIDSaved]);
	// ready condition
	const mounted =
		!isServer &&
		fpCheckDone &&
		pageType !== undefined &&
		(pageType === AnalyticsPageTypes.GAMEPAGE
			? !!game
			: pageType === AnalyticsPageTypes.GENERIC || pageType === AnalyticsPageTypes.GENERIC_HOME
				? modularPageID !== undefined
				: true);
	// run logic
	useEffect(() => {
		if (!mounted) {
			return;
		}
		const isGenericPage = pageType && GENERIC_PAGES_ARR.includes(pageType);
		setIsGenericPage(isGenericPage);
		const addedSpecialParams = {
			isGenericPage,
			browser: browserName,
			...(isGenericPage ? { modularPageID } : {}),
			...(pageType === AnalyticsPageTypes.GAMEPAGE
				? { gameName: gameSlug }
				: pageType === AnalyticsPageTypes.CATEGORY
					? { categoryName: window.location.pathname.split('/').pop() }
					: pageType === AnalyticsPageTypes.SEARCH
						? { searchQuery: window.location.pathname.split('/').pop() }
						: pageType === AnalyticsPageTypes.TAGS
							? { tag: window.location.pathname.split('/').pop() }
							: {}),
			...(game && typeof window !== 'undefined' && window.location.search.includes('recommendedGame')
				? { recommendedGameStart: 'Yes' }
				: { recommendedGameStart: 'No' }),
		};
		// filled data
		const aiDataFilled = Object.assign(
			{},
			{
				baseParams: {
					...baseParams(pageType, fpVisitorId, fpIncognito, deviceType, loggedIn, gdprConsent),
					...addedSpecialParams,
				},
				baseCustomDimensions: {
					arenaID,
					arenaLayoutID,
					utmSource: getSearchParams(window.location.search, 'utmSource'),
					utmMedium: getSearchParams(window.location.search, 'utmMedium'),
					utmCampaign: getSearchParams(window.location.search, 'utmCampaign'),
				},
			},
		);
		aiDataRef.current = { ...aiDataFilled } as AiData;
		setAIReady(
			!isServer &&
				Boolean(aiDataRef.current) &&
				AILoadReady &&
				Boolean(
					aiDataRef?.current?.baseParams?.domain &&
						Boolean(aiDataRef?.current?.baseCustomDimensions?.arenaLayoutID) &&
						Boolean(isGDPRApplies ? targetingConsent : true),
				) &&
				Boolean(
					!isGenericPage || modularPageID, // generic page should always have modular page id for analytics
				),
		);
	}, [
		AILoadReady,
		fpCheckDone,
		aiData,
		mounted,
		isServer,
		pathName,
		pageType,
		isGenericPage,
		deviceType,
		loggedIn,
		fpVisitorId,
		fpIncognito,
		buildVersion,
		arenaVersion,
		arenaID,
		arenaLayoutID,
		modularPageID,
		gameSlug,
		slugCategories,
		genericPageData?.slug,
		targetingConsent,
	]);
	const baseParamsOut: TAnalyticsBaseParams & TPlayAgainStartParam & TRecommendedGameStart = {
		...(aiDataRef?.current?.baseParams ?? ({} as any)),
		...(aiDataRef?.current?.baseCustomDimensions ?? ({} as any)),
	} as unknown as TAnalyticsBaseParams;
	const aiTrackGeneric = async (data: {
		eventName: string;
		isNonInteraction: boolean;
		eventAction: AnalyticsEventAction;
		customDimensions?: any;
	}) => {
		const { eventName, eventAction, isNonInteraction, customDimensions } = data,
			generalAnalytics = await GeneralAnalytics.getInstance(),
			eventPair = generalAnalytics.genericAiEvent(
				baseParamsOut,
				eventAction as any,
				eventName,
				isNonInteraction,
				game as any,
				{
					...(aiDataRef?.current?.baseCustomDimensions ?? {}),
					...(aiDataRef?.current?.baseParams ?? {}),
					...(customDimensions && typeof customDimensions === 'object' && !Array.isArray(customDimensions)
						? customDimensions
						: {}),
				},
			);
		logger.debug('AI - trackEvent', eventName, eventPair.ai);
		trackEvent(eventPair);
	};
	// export static class to use
	const arenaDomain = aiDataRef?.current?.baseParams?.domain || '';
	const href = !isServer ? window.location.href : '';
	const pageNameForAnalytics = aiDataRef?.current?.baseCustomDimensions?.modularPageID || '';
	class AITracks {
		// GENERIC
		static genericTrack = (
			data:
				| {
						eventName: string;
						isNonInteraction: boolean;
						eventAction: AnalyticsEventAction;
						customDimensions?: any;
				  }
				| any,
			force = false,
		) =>
			(AIReady || force) &&
			aiTrackGeneric({ ...data } as {
				eventName: string;
				isNonInteraction: boolean;
				eventAction: AnalyticsEventAction;
				customDimensions?: any;
			});
		// PAGE VIEW
		static PageView = (customDimensions?: any, force = false) => {
			logger.debug('AI - trackEvent', 'TrackPageView', baseParamsOut);
			return (
				(AIReady || force) &&
				AppInsightService.trackPageView(
					typeof window !== 'undefined' ? window.location.href : '',
					baseParamsOut,
					baseParamsOut?.screenResolution as any,
				)
			);
		};
		static tagButtonClick = (tagText: string, force = false) =>
			(AIReady || force) &&
			aiTrackGeneric({
				eventName: 'TagButton',
				isNonInteraction: false,
				eventAction: AnalyticsEventAction.CLICK,
				customDimensions: { tagText: tagText || '' },
			});
		// GAME START
		static funnelPlayNow = (playAgainStart: TPlayAgainStart, force = false) =>
			(AIReady || force) &&
			game &&
			aiTrackGeneric({
				eventName: 'funnelPlayNow',
				isNonInteraction: false,
				eventAction: AnalyticsEventAction.CLICK,
				customDimensions: {
					playAgainStart,
				},
			});
		static funnelPrerollRequest = async (playAgainStart: TPlayAgainStart, force = false) =>
			(AIReady || force) &&
			game &&
			trackEvent(
				(await GamesAnalytics.getInstance()).prerollStart(
					{ ...baseParamsOut, playAgainStart } as any,
					game as any,
					arenaDomain,
					href,
				),
			);
		static funnelPrerollEnd = async (duration: number, playAgainStart: TPlayAgainStart, force = false) =>
			(AIReady || force) &&
			game &&
			trackEvent(
				(await GamesAnalytics.getInstance()).prerollEnd(
					{ ...baseParamsOut, playAgainStart } as any,
					game as any,
					duration,
					href,
				),
			);
		static adBlockerPopup = async (playAgainStart: TPlayAgainStart, force = false) =>
			(AIReady || force) &&
			game &&
			trackEvent(
				(await GamesAnalytics.getInstance()).adblockImpression(
					{ ...baseParamsOut, playAgainStart } as any,
					game as any,
				),
			);
		// GAME PLAY
		static funnelContentStart = async (playAgainStart: TPlayAgainStart, force = false) =>
			(AIReady || force) &&
			game &&
			trackEvent(
				(await GamesAnalytics.getInstance()).gameStart(
					{ ...baseParamsOut, playAgainStart } as any,
					game as any,
					arenaDomain,
					href,
				),
			);
		// GAME IN-GAME
		static ingameMainScreenReady = (customDimensions?: any, force = false) =>
			(AIReady || force) &&
			aiTrackGeneric({
				eventName: 'MainScreenReady',
				isNonInteraction: true,
				eventAction: AnalyticsEventAction.IMPRESSION,
				customDimensions,
			});
		static ingameAppStarted = (customDimensions?: any, force = false) =>
			(AIReady || force) &&
			aiTrackGeneric({
				eventName: 'AppStarted',
				isNonInteraction: true,
				eventAction: AnalyticsEventAction.IMPRESSION,
				customDimensions,
			});
		static ingameStartButtonClicked = (customDimensions?: any, force = false) =>
			(AIReady || force) &&
			aiTrackGeneric({
				eventName: 'Start_Button_Clicked',
				isNonInteraction: false,
				eventAction: AnalyticsEventAction.CLICK,
				customDimensions,
			});
		static ingameGameEventTrack = (msgPayload: any, updateGemsAmountFx: () => void = () => {}) => {
			try {
				const eventName = msgPayload?.event_name ?? '';
				const eventPayload = msgPayload?.payload ?? {};
				const gameAnalyticsSlug = (game as any)?.analyticsSlug ?? (game as any)?.arkadiumSlug ?? gameSlug;
				// -----> GAME-END EVENT IN-GAME DUPLICATION WITH WRONG CDS HANDLE (START)
				if (eventName === 'GameEnd' && !(this?.ingameGameEventTrack as any)?.isGameEndSent) {
					trackGameEvent(baseParamsOut as any, eventName, eventPayload, gameAnalyticsSlug);
					(this.ingameGameEventTrack as any).isGameEndSent = true;
				} else if (eventName === 'GameEnd') {
					(this.ingameGameEventTrack as any).isGameEndSent = false;
					// -----> GAME-END EVENT IN-GAME DUPLICATION WITH WRONG CDS HANDLE (END)
				} else {
					trackGameEvent(baseParamsOut as any, eventName, eventPayload, gameAnalyticsSlug);
				}
				if (updateGemsAmountFx && eventName?.toLowerCase?.() === 'Boost'.toLowerCase()) {
					updateGemsAmountFx();
				}
			} catch (e) {
				console.error(e);
			}
		};
		// GAME VIDEO ADS
		static funnelReward = async (playAgainStart: TPlayAgainStart, force = false) =>
			(AIReady || force) &&
			game &&
			trackEvent(
				(await GamesAnalytics.getInstance()).rewardVideoStart(
					{ ...baseParamsOut, playAgainStart } as any,
					game as any,
					arenaDomain,
				),
			);
		static funnelRewardEnd = async (playAgainStart: TPlayAgainStart, force = false) =>
			(AIReady || force) &&
			game &&
			trackEvent(
				(await GamesAnalytics.getInstance()).rewardVideoEnd(
					{ ...baseParamsOut, playAgainStart } as any,
					game as any,
				),
			);
		static funnelInterstitialRequest = async (playAgainStart: TPlayAgainStart, force = false) =>
			(AIReady || force) &&
			game &&
			trackEvent(
				(await GamesAnalytics.getInstance()).interstitialVideoStart(
					{ ...baseParamsOut, playAgainStart } as any,
					game as any,
					arenaDomain,
				),
			);
		static funnelInterstitialEnd = async (playAgainStart: TPlayAgainStart, force = false) =>
			(AIReady || force) &&
			game &&
			trackEvent(
				(await GamesAnalytics.getInstance()).interstitialVideoEnd(
					{ ...baseParamsOut, playAgainStart } as any,
					game as any,
				),
			);
		// GAME END
		static funnelContentEnd = async (duration: number, playAgainStart: TPlayAgainStart, force = false) =>
			(AIReady || force) &&
			game &&
			trackEvent(
				(await GamesAnalytics.getInstance()).gameEnd(
					{ ...baseParamsOut, playAgainStart } as any,
					game as any,
					duration,
					arenaDomain,
					href,
				),
			);
		static funnelEndButtonPlayAgain = async (force = false) =>
			(AIReady || force) &&
			game &&
			trackEvent(
				(await GamesAnalytics.getInstance()).playAgainClick(baseParamsOut, game as any, arenaDomain, href),
			);
		static recommendedGamesClick = async (gameName: string, force = false) =>
			(AIReady || force) &&
			game &&
			trackEvent(
				(await GamesAnalytics.getInstance()).recommendedGamesClickNew(baseParamsOut, game as any, gameName),
			);
		// DISPLAY ADS
		static displayAd = async (adEl: any, refreshed: any, force = false) =>
			(AIReady || force) &&
			trackEvent(
				(await GeneralAnalytics.getInstance()).displayAd(
					{
						...baseParamsOut,
						...(isGenericPage ? { modularPageID } : {}),
						id: adEl?.parentElement?.getAttribute?.('data-id') ?? '',
					} as any,
					refreshed,
					(adEl?.parentElement as any)?.isViewable?.(),
					game as any,
					[adEl?.offsetWidth, adEl?.offsetHeight],
					adEl?.parentElement?.getAttribute?.('data-id') ?? pageType,
					arenaDomain,
					pageNameForAnalytics,
					href,
				),
			);
		// ADBLOCK SCREEN
		static adBlockerImpression = (playAgainStart: TPlayAgainStart, force = false) =>
			(AIReady || force) &&
			aiTrackGeneric({
				eventName: 'adBlockPopup',
				isNonInteraction: true,
				eventAction: AnalyticsEventAction.IMPRESSION,
				customDimensions: { playAgainStart },
			});
		static adBlockerClick = (force = false) =>
			(AIReady || force) &&
			aiTrackGeneric({
				eventName: 'adBlockButtonClick',
				isNonInteraction: false,
				eventAction: AnalyticsEventAction.CLICK,
			});
		// SEARCH
		static searchNav = (force = false) =>
			(AIReady || force) &&
			aiTrackGeneric({
				eventName: 'SearchNav',
				isNonInteraction: false,
				eventAction: AnalyticsEventAction.CLICK,
			});
		static searchBoxQuery = (searchQuery: string, force = false) =>
			(AIReady || force) &&
			aiTrackGeneric({
				eventName: 'SearchBox',
				isNonInteraction: false,
				eventAction: AnalyticsEventAction.QUERY,
				customDimensions: { searchQuery },
			});
		// NAVIGATION
		static topNavClick = async (category: string, index: number, force = false) =>
			(AIReady || force) &&
			trackEvent((await GeneralAnalytics.getInstance()).topNavClick(baseParamsOut, category, index));
		static sideNavClick = (category: string, index: number, force = false) =>
			(AIReady || force) &&
			aiTrackGeneric({
				eventName: 'CatNav',
				isNonInteraction: false,
				eventAction: AnalyticsEventAction.CLICK,
				customDimensions: { category, index },
			});
		// FOOTER
		static footerTrackSocialLinkClick = (link: TLink) => {
			AIReady &&
				aiTrackGeneric({
					eventName: 'SocialLinkClick',
					isNonInteraction: false,
					eventAction: AnalyticsEventAction.CLICK,
					customDimensions: { label: link.label, href: link.href },
				});
		};
		static footerTrackGroupLinkClick = (link: TLink) => {
			AIReady &&
				aiTrackGeneric({
					eventName: 'GroupLinkClick',
					isNonInteraction: false,
					eventAction: AnalyticsEventAction.CLICK,
					customDimensions: { label: link.label, href: link.href },
				});
		};
		static footerTrackGDPRClick = () => {
			AIReady &&
				aiTrackGeneric({
					eventName: 'GDPRLinkClick',
					isNonInteraction: false,
					eventAction: AnalyticsEventAction.CLICK,
				});
		};
		static footerTrackUSPrivacyClick = () => {
			AIReady &&
				aiTrackGeneric({
					eventName: 'USPrivacyLinkClick',
					isNonInteraction: false,
					eventAction: AnalyticsEventAction.CLICK,
				});
		};
		static footerTrackMicrosoftClick = () => {
			AIReady &&
				aiTrackGeneric({
					eventName: 'MicrosoftPrivacyLinkClick',
					isNonInteraction: false,
					eventAction: AnalyticsEventAction.CLICK,
				});
		};
		// NOTIFICATION
		static notificationAction = (customDimensions: any) => {
			AIReady &&
				aiTrackGeneric({
					eventName: 'notifBarCTA',
					isNonInteraction: false,
					eventAction: AnalyticsEventAction.CLICK,
					customDimensions,
				});
		};
		static notificationImpression = async (customDimensions: any) => {
			AIReady &&
				(await aiTrackGeneric({
					eventName: 'notifBar',
					isNonInteraction: false,
					eventAction: AnalyticsEventAction.IMPRESSION,
					customDimensions,
				}));
		};
		// WEB-VITALS
		static webVitalsSingleMetric = (metricName: string, metric: Metric) => {
			AIReady &&
				aiTrackGeneric({
					eventName: 'WebVitals',
					isNonInteraction: true,
					eventAction: AnalyticsEventAction.IMPRESSION,
					customDimensions: {
						[metricName]: { ...metric },
						name: metricName,
					},
				});
		};
		static webVitalsAllMetrics = (allMetrics: {
			LCP?: { value: number; delta: number; rating: number };
			FCP?: { value: number; delta: number; rating: number };
			TTFB?: { value: number; delta: number; rating: number };
			CLS?: { value: number; delta: number; rating: number };
		}) => {
			AIReady &&
				aiTrackGeneric({
					eventName: 'WebVitals',
					isNonInteraction: true,
					eventAction: AnalyticsEventAction.IMPRESSION,
					customDimensions: {
						...allMetrics,
						name: 'allMetrics',
					},
				});
			allMetrics = {};
		};
	}
	const AIReadyAsDep = AIReady;
	return [AITracks as AITracks | any, AIReadyAsDep];
};

const notInitiated = (trackName: string) =>
	console.warn(
		`@AITracks: ${trackName ? trackName + ' - ' : ''} This is fallback's call. AITRack class not initiated yet.`,
	);
export class AITracksDefault {
	// For more robust fallback if not initiated occasionally
	static genericTrack = () => notInitiated('genericTrack');
	static PageView = () => notInitiated('PageView');
	static tagButtonClick = () => notInitiated('tagButtonClick');
	static funnelPlayNow = () => notInitiated('funnelPlayNow');
	static funnelPrerollRequest = () => notInitiated('funnelPrerollRequest');
	static funnelPrerollEnd = () => notInitiated('funnelPrerollEnd');
	static adBlockerPopup = () => notInitiated('adBlockerPopup');
	static funnelContentStart = () => notInitiated('funnelContentStart');
	static ingameMainScreenReady = () => notInitiated('ingameMainScreenReady');
	static ingameAppStarted = () => notInitiated('ingameAppStarted');
	static ingameStartButtonClicked = () => notInitiated('ingameStartButtonClicked');
	static ingameGameEventTrack = () => notInitiated('ingameGameEventTrack');
	static funnelReward = () => notInitiated('funnelReward');
	static funnelRewardEnd = () => notInitiated('funnelRewardEnd');
	static funnelInterstitialRequest = () => notInitiated('funnelInterstitialRequest');
	static funnelInterstitialEnd = () => notInitiated('funnelInterstitialEnd');
	static funnelContentEnd = () => notInitiated('funnelContentEnd');
	static funnelEndButtonPlayAgain = () => notInitiated('funnelEndButtonPlayAgain');
	static recommendedGamesClick = () => notInitiated('recommendedGamesClick');
	static displayAd = () => notInitiated('displayAd');
	static adBlockerImpression = () => notInitiated('adBlockerImpression');
	static adBlockerClick = () => notInitiated('adBlockerClick');
	static searchNav = () => notInitiated('searchNav');
	static searchBoxQuery = () => notInitiated('searchBoxQuery');
	static topNavClick = () => notInitiated('topNavClick');
	static sideNavClick = () => notInitiated('sideNavClick');
	static footerTrackSocialLinkClick = () => notInitiated('footerTrackSocialLinkClick');
	static footerTrackGroupLinkClick = () => notInitiated('footerTrackGroupLinkClick');
	static footerTrackGDPRClick = () => notInitiated('footerTrackGDPRClick');
	static footerTrackUSPrivacyClick = () => notInitiated('footerTrackUSPrivacyClick');
	static footerTrackMicrosoftClick = () => notInitiated('footerTrackMicrosoftClick');
	static notificationAction = () => notInitiated('notificationAction');
	static notificationImpression = () => notInitiated('notificationImpression');
	static webVitalsSingleMetric = () => notInitiated('webVitalsSingleMetric');
	static webVitalsAllMetrics = () => notInitiated('webVitalsAllMetrics');
}
